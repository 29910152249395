import { apiClient } from '@/custom_functions/api_client';
import validateParticipation from '@/custom_functions/validation_functions/validate_participation';

export default {
  namespaced: true,

  state: {
    participations: null,
    errors: {},
  },

  getters: {
    getUserParticipations(state) {
      return state.participations;
    },

    getErrors(state) {
      return state.errors;
    },
  },

  actions: {
    async fetchUserParticipations({ commit }, payload) {
      const response = await apiClient.get(
        `/v2/users/${payload.userId}/participations`,
      );

      commit('updateUserParticipations', response.data.participations);
    },

    async addParticipation({ state, dispatch }, participation) {
      dispatch('validateParticipation', participation);
      if (!Object.keys(state.errors).length) {
        await apiClient.post('/v2/participations', {
          participation: {
            student_id: participation.userId,
            course_id: participation.courseId,
            company_id: participation.companyId,
          },
        });
      }
    },

    async validateParticipation({ commit }, participation) {
      commit('updateErrors', validateParticipation(participation));
    },
  },

  mutations: {
    updateUserParticipations(state, participations) {
      state.participations = participations;
    },

    spliceUserParticipation(state, participationId) {
      state.participations.splice(
        state.participations.findIndex((p) => p.id === participationId),
        1,
      );
    },

    updateErrors(state, errors) {
      state.errors = errors;
    },
  },
};
