import validateCourseDateFrom from './helpers/course/validate_course_date_from';
import validateCourseDateTo from './helpers/course/validate_course_date_to';
import validateCourseExpireDays from './helpers/course/validate_course_expire_days';
import validateCourseTypes from './helpers/course/validate_course_types';
import validateNotEmptyField from './helpers/validate_not_empty_field';
import validateStringLength from './helpers/validate_string_length';
import validateZoomUrl from './helpers/validate_zoom_url';

export default function validateCourse(value) {
  const result = {};

  const checkKey = (key, validateFunc, payload = null) => {
    const errors = validateFunc(value[key], payload);
    if (errors.length) result[key] = errors;
  };

  if (value) {
    checkKey('displayed_type', validateCourseTypes, value.course_type);

    checkKey('product_ids', validateNotEmptyField);

    const streamName = validateStringLength(value.stream_name, 10);
    if (streamName.length) {
      result.stream_name = result.stream_name
        ? [...result.stream_name, ...streamName]
        : streamName;
    }

    const tariffName = validateStringLength(value.tariff_name, 40);
    if (tariffName.length) {
      result.tariff_name = result.tariff_name
        ? [...result.tariff_name, ...tariffName]
        : tariffName;
    }
    checkKey('po_user_id', validateNotEmptyField);
    checkKey('expire_days', validateCourseExpireDays);

    if (value.course_type === 'slurm') checkKey('zoom_url', validateZoomUrl);

    checkKey('price', validateNotEmptyField);

    if (
      value.course_type === 'slurm' ||
      value.displayed_type === 'online_course'
    ) {
      checkKey('date_from', validateCourseDateFrom);
      checkKey('date_to', validateCourseDateTo, { dateFrom: value.date_from });
    }
  } else {
    result.course = [];
    if (!value) result.course.push('Тариф не может быть пустым');
  }

  console.error(result); // eslint-disable-line no-console
  return result;
}
